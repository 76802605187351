import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import '../css/Site.css';
import bek_slika from '../imgs/backs/regMrezaBack.jpg';

class RegMreza extends Component {
    constructor(props){
        super(props);
        this.props=props;
    }
    render() {
        var tekst = (
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            className="mx-5 my-0 px-md-0 my-5 my-md-0"
          >
            {/* <article>
                <p className="naslovKontakt p-0 m-0">Plus Media Beograd</p>
                <p className="p-0 m-0">Đorđa Stanojevića 11b</p>
                <p className="pt-0 mt-0">tel. +381 (0) 11 630 0585</p>
            </article>
            <article>
                <p className="naslovKontakt pb-0 mb-0">Plus Media Banja Luka</p>
                <p className="p-0 m-0">Srpskih pilota 30</p>
                <p className="pt-0 mt-0">tel. +387 (0) 51 345 630</p>
            </article>
            <article>
                <p className="naslovKontakt pb-0 mb-0">Plus Media Sarajevo</p>
                <p className="p-0 m-0">Isa-Bega Isakovića 6</p>
                <p className="pt-0 mt-0">tel. +387 (33) 572 040</p>
            </article>
            <article>
                <p className="naslovKontakt pb-0 mb-0">Plus Media Podgorica</p>
                <p className="p-0 m-0">Slobode 74/II</p>
                <p className="pt-0 mt-0">tel. +382 (20) 269 117</p>
            </article>
            <article>
                <p className="naslovKontakt pb-0 mb-0">Plus Media Skoplje</p>
                <p className="p-0 m-0">Crvena Voda 7/18</p>
                <p className="pt-0 mt-0">tel. +389 2 322 1454</p>
            </article> */}
            <article>
              <p className="naslovKontakt p-0 m-0">PLUS MEDIA SRBIJA</p>
              <p className="p-0 m-0">Bulevar Zorana Đinđića 71/9/III</p>
              <p className="p-0 m-0">11070 Novi Beograd</p>
              <p className="p-0 m-0">
                Tel. <a href="tel:+381114024350">(+381 11) 402 43 50</a>
              </p>
              <p className="pt-0 mt-0">
                E-mail:{" "}
                <a href="mailto:office@plusmedia.rs">office@plusmedia.rs</a>
              </p>
            </article>
            <article>
              <p className="naslovKontakt pb-0 mb-0">PLUS MEDIA D.O.O. BIH</p>
              <p className="p-0 m-0">Jovana Dučića 74A</p>
              <p className="p-0 m-0">78000 Banja Luka</p>
              <p className="p-0 m-0">
                Tel. <a href="tel:+38751345630">(+387 51) 345 630</a>
              </p>
              <p className="pt-0 mt-0">
                E-mail:{" "}
                <a href="mailto:office@plusmedia-rs.com">
                  office@plusmedia-rs.com
                </a>
              </p>
            </article>
            <article>
              <p className="naslovKontakt pb-0 mb-0">
                PLUS MEDIA D.O.O. CRNA GORA
              </p>
              <p className="p-0 m-0">Cetinjski put UP1PPL5</p>
              <p className="p-0 m-0">81120 Podgorica</p>
              <p className="p-0 m-0">
                Tel. <a href="tel:+38220206960">(+382 20) 206 960</a>
              </p>
              <p className="pt-0 mt-0">
                E-mail:{" "}
                <a href="mailto:office@plusmedia.me">office@plusmedia.me</a>
              </p>
            </article>
            <article>
              <p className="naslovKontakt pb-0 mb-0">PLUS MEDIA DOOEL SKOPJE</p>
              <p className="p-0 m-0">Aminta 3, 6/1</p>
              <p className="p-0 m-0">1000 Skopje Macedonia</p>
              <p className="p-0 m-0">
                Tel. <a href="tel:+38923221454">(+389 2) 322 14 54</a>
              </p>
              <p className="pt-0 mt-0">
                E-mail:{" "}
                <a href="mailto:office@plusmedia.mk">office@plusmedia.mk</a>
              </p>
            </article>
          </Col>
        );
        if (this.props.lang === "en") {
            tekst = (
              <Col
                xs={{ span: 12, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                className="mx-5 my-0 px-md-0 my-5 my-md-0 mx-md-0"
              >
                {/* <article>
                    <p className="naslovKontakt p-0 m-0">Plus Media Belgrade</p>
                    <p className="p-0 m-0">Đorđa Stanojevića 11b</p>
                    <p className="pt-0 mt-0">tel. +381 (0) 11 630 0585</p>
                </article>
                <article>
                    <p className="naslovKontakt pb-0 mb-0">Plus Media Banja Luka</p>
                    <p className="p-0 m-0">Srpskih pilota 30</p>
                    <p className="pt-0 mt-0">tel. +387 (0) 51 345 630</p>
                </article>
                <article>
                    <p className="naslovKontakt pb-0 mb-0">Plus Media Sarajevo</p>
                    <p className="p-0 m-0">Isa-Bega Isakovića 6</p>
                    <p className="pt-0 mt-0">tel. +387 (33) 572 040</p>
                </article>
                <article>
                    <p className="naslovKontakt pb-0 mb-0">Plus Media Podgorica</p>
                    <p className="p-0 m-0">Slobode 74/II</p>
                    <p className="pt-0 mt-0">tel. +382 (20) 269 117</p>
                </article>
                <article>
                    <p className="naslovKontakt pb-0 mb-0">Plus Media Skoplje</p>
                    <p className="p-0 m-0">Crvena Voda 7/18</p>
                    <p className="pt-0 mt-0">tel. +389 2 322 1454</p>
                </article> */}
                <article>
                  <p className="naslovKontakt p-0 m-0">PLUS MEDIA SRBIJA</p>
                  <p className="p-0 m-0">Bulevar Zorana Đinđića 71/9/III</p>
                  <p className="p-0 m-0">11070 Novi Beograd</p>
                  <p className="p-0 m-0">
                    Tel. <a href="tel:+381114024350">(+381 11) 402 43 50</a>
                  </p>
                  <p className="pt-0 mt-0">
                    E-mail:{" "}
                    <a href="mailto:office@plusmedia.rs">office@plusmedia.rs</a>
                  </p>
                </article>
                <article>
                  <p className="naslovKontakt pb-0 mb-0">
                    PLUS MEDIA D.O.O. BIH
                  </p>
                  <p className="p-0 m-0">Jovana Dučića 74A</p>
                  <p className="p-0 m-0">78000 Banja Luka</p>
                  <p className="p-0 m-0">
                    Tel. <a href="tel:+38751345630">(+387 51) 345 630</a>
                  </p>
                  <p className="pt-0 mt-0">
                    E-mail:{" "}
                    <a href="mailto:office@plusmedia-rs.com">
                      office@plusmedia-rs.com
                    </a>
                  </p>
                </article>
                <article>
                  <p className="naslovKontakt pb-0 mb-0">
                    PLUS MEDIA D.O.O. CRNA GORA
                  </p>
                  <p className="p-0 m-0">Cetinjski put UP1PPL5</p>
                  <p className="p-0 m-0">81120 Podgorica</p>
                  <p className="p-0 m-0">
                    Tel. <a href="tel:+38220206960">(+382 20) 206 960</a>
                  </p>
                  <p className="pt-0 mt-0">
                    E-mail:{" "}
                    <a href="mailto:office@plusmedia.me">office@plusmedia.me</a>
                  </p>
                </article>
                <article>
                  <p className="naslovKontakt pb-0 mb-0">
                    PLUS MEDIA DOOEL SKOPJE
                  </p>
                  <p className="p-0 m-0">Aminta 3, 6/1</p>
                  <p className="p-0 m-0">1000 Skopje Macedonia</p>
                  <p className="p-0 m-0">
                    Tel. <a href="tel:+38923221454">(+389 2) 322 14 54</a>
                  </p>
                  <p className="pt-0 mt-0">
                    E-mail:{" "}
                    <a href="mailto:office@plusmedia.mk">office@plusmedia.mk</a>
                  </p>
                </article>
              </Col>
            );
        }
        var levitekst = this.props.lang === "en" ?
            <Col xs={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} className="leviTekst mx-3 p-xxs-3 mx-md-0 my-0 px-5">
                <p>We offer our services in Serbia, Montenegro, Macedonia and Bosnia and Herzegovina. Our regional outreach began as means to ensure detailed data and a direct presence on the markets in which our clients do business.</p>
                <p>Due to this approach, our regional offices have gained a reputation for service of the highest standard, thus attracting numerous local clients and positioning themselves as successful, independent local agencies that make up the foundation of our growing regional network.</p>
                <p className="naslovKontakt">Our finger is on the pulse of the region. Our view spans the entire world.</p>
            </Col>
            :
            <Col xs={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} className="leviTekst mx-3 p-xxs-3 mx-md-0 my-0 px-5">
                <p>Usluge nudimo na tržištima Srbije, Crne Gore, Makedonije, i Bosne i Hercegovine. Regionalnu delatnost smo započeli kako bismo obezbedili direktno prisustvo i detaljne podatke sa svih tržišta na kojima posluju naši klijenti, u skladu sa našom vizijom poslovanja.</p>
                <p>Zahvaljujući ovakvom nastupu, naše regionalne kancelarije su svojim izuzetnim kvalitetom usluge privukle brojne lokalne klijente i pozicionirale se kao uspešne, nezavisne lokalne agencije, koje istovremeno čine okosnicu naše rastuće regionalne mreže.</p>
                <p className="naslovKontakt">Naš prst je na pulsu regiona. Naš pogled obuhvata ceo svet.</p>
            </Col>;
        return (           
            <Row className="sekcija w-100 ml-3">
                <Row className='w-100 mx-0'>
                    <Col className="p-0" xs={12}>
                        <img alt={this.props.lang === "en" ? "Regional network" : "Regionalna mreža"} src={bek_slika} className="w-100" />
                    </Col>
                </Row > 
                <Row className="ispisOnama center col-12">
                    <Col xs={{ span: 10, offset: 2 }} md={{ span: 12, offset: 0 }} className="naslov text-left  text-md-center pl-0 pt-5">{this.props.lang === "en" ? "Regional network" : "Regionalna mreža"}</Col>                 
                    <Col xs={{ span: 10, offset: 1 }} ><hr className="mb-5 background mx-auto" style={{ backgroundColor: 'black' }} /></Col>                   
                    <Row className='col-12 px-0 px-sm-5 justify-content-center'>  
                        <Col md={{ span: 6, offset: 1 }} lg={{ span: 5, offset: 2 }} xl={{ span: 4, offset: 3 }}>{levitekst}</Col>
                        <Col md={5}>{tekst}</Col>           
                    </Row>
                </Row>
            </Row>     
        );
    }
}

export default RegMreza;