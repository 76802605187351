import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { ButtonGroup } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import './css/Site.css';
import Zaglavlje from './Zaglavlje';
import Podnozje from './Podnozje';
import Container from 'react-bootstrap/lib/Container';
import Sadrzaj from './Sadrzaj';

library.add(faCoffee, faAngleDown);
class Site extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: "sr",
            srlink: "/",
            enlink: "/en",
            currentUrl: ""
        }
        this.props = props;
    }
    uSrpskiLink(link) {
        switch (link) {
            case 'our-story': return 'o-nama';
            case 'regional-network': return 'reg_mreza';
            case 'services': return 'usluge';
            case 'contact-us': return 'kontakt';
            default: return ''
        }
    }
    uEngleskiLink(link) {
        switch (link) {
            case 'o-nama': return 'our-story';
            case 'reg_mreza': return 'regional-network';
            case 'usluge': return 'services';
            case 'kontakt': return 'contact-us';
            default: return ''
        }
    }
    defineURL(url_niz) {
        if (url_niz.length > 3) {
            switch (url_niz[3]) {
                case 'en':
                    if (url_niz.length > 4) {
                        this.setState({
                            lang: "en",
                            srlink: "/" + this.uSrpskiLink(url_niz[4]),
                            enlink: "/en/" + url_niz[4],
                            currentUrl: window.location.href
                        });
                    }
                    else {
                        this.setState({
                            lang: "en",
                            srlink: "/",
                            enlink: "/en",
                            currentUrl: window.location.href
                        });
                    }

                    break;
                case '':
                    this.setState({
                        lang: "sr",
                        srlink: "/",
                        enlink: "/en",
                        currentUrl: window.location.href
                    });
                    break;
                default:
                    this.setState({
                        lang: "sr",
                        srlink: "/" + url_niz[3],
                        enlink: "/en/" + this.uEngleskiLink(url_niz[3]),
                        currentUrl: window.location.href
                    });
                    break;
            }
        }
    }

    componentDidMount() {
        // this.state.lang === 'sr' ?
        //     document.getElementById("SrbLangBtn").classList.add("jezikAktiv")
        //     :
        //     document.getElementById("EngLangBtn").classList.add("jezikAktiv");
        var url_niz = window.location.href.split('/');
        this.defineURL(url_niz);
    }
    componentDidUpdate() {
        var url_niz = window.location.href.split('/');
        if (this.state.currentUrl !== window.location.href) {
            this.defineURL(url_niz);
        }
    }
    DugmeSRBKlik = (e) => {
        this.setState({
            lang: "sr"
        });
        // document.getElementById("SrbLangBtn").classList.add("jezikAktiv"); //
        // document.getElementById("EngLangBtn").classList.remove("jezikAktiv");
    }
    DugmeENGKlik = (e) => {
        this.setState({
            lang: "en"
        });
        // document.getElementById("EngLangBtn").classList.add("jezikAktiv");
        // document.getElementById("SrbLangBtn").classList.remove("jezikAktiv");
    }
    render() {
        this.srbJezik = this.state.lang === 'sr';
        return (
            <Container fluid="true" className="kontejner px-0 mx-0">
                <Row className='w-100 px-0 mx-0'>
                    <Col className="p-0 mt-3" xs={11} md={{span:11, offset:0}} lg={{span:11, offset:1}} xl={{span:10, offset:1}} >
                        <Zaglavlje lang={this.state.lang} />
                    </Col>
                    <Col className="p-0 mt-3 mr-5 pr-5 jezik pt-md-1" xs={{ span: 1, offset: 10 }} lg={{ span: 1, offset: 11 }} xl={{ span: 1, offset: 10 }}>
                        <ButtonGroup className="langBar mt-sm-0 mt-md-0">
                            <div id="SrbLangBtn" variant="link" className='pt-3 pt-md-3 mt-1'>
                                <NavLink className={`jezikMeni ${this.srbJezik ? 'jezikAktiv' : ''}`} id="SrbLink" to={this.state.srlink} onClick={(e) => this.DugmeSRBKlik(e)} >SRB</NavLink>
                            </div>
                            <div className="mt-3 pt-1 pb-1 px-1 frontBoja">|</div>
                            <div id="EngLangBtn" variant="link" className='pt-3 pt-md-3 mt-1'>
                                <NavLink id="EngLink" className={`jezikMeni ${!this.srbJezik ? 'jezikAktiv' : ''}`} to={this.state.enlink} onClick={(e) => this.DugmeENGKlik(e)} >ENG</NavLink>
                            </div>
                        </ButtonGroup>
                    </Col>
                </Row>

                <Sadrzaj lang={this.state.lang} />
                <Podnozje lang={this.state.lang} />
            </Container>
        );
    }
}

export default Site;