import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import '../css/Site.css';
import bek_slika from '../imgs/backs/rectangle.jpg';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: localStorage.vesti === undefined ? this.props.lang === 'en' ? [{ date: '', link: '', title: 'News', description: 'Loading...' }] : [{ date: '', link: '', title: 'Vesti', description: 'Učitava se...' }] : JSON.parse(localStorage.vesti)
        }
        this.props = props;
    }
    componentDidMount() {
        fetch("https://plusmedia.rs/RssPhpServer/service.php")
            .then(res => res.json())
            .then(
                (result) => {
                    localStorage.vesti = JSON.stringify(result.poruka);
                    this.setState({
                        isLoaded: true,
                        items: result.poruka,
                        status: result.status
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    render() {
        const {/* error, isLoaded,*/ items/*, status*/ } = this.state;
        var naslov0 = <Row className='w-100'>
            <Col xs={{ span: 10, offset: 2 }} md={{ span: 12, offset: 0 }} className="naslov text-left  text-md-center pt-5">Vesti</Col>
        </Row>;
        if (this.props.lang === "en") {
            naslov0 = <Row className='w-100'>
                <Col xs={{ span: 10, offset: 2 }} md={{ span: 12, offset: 0 }} className="naslov text-left  text-md-center pt-5">News</Col>
            </Row>;
        }
        return (
            <section>
                <Row className='justify-content-center'>
                    <Col className="p-0 justify-content-center" xs={12}>
                        <img alt={this.props.lang === "en" ? "PlusMedia" : "PlusMedia"} src={bek_slika} className="w-100" />
                        <div className="ispis h-100 w-auto px-xl-5 justify-content-center">
                            “We have technology, finally, that for the first time in human history allows people to really maintain rich connections with much larger numbers of people.”
                            <div className="potpis pt-3 pt-lg-5">
                                –  Pierre Omidyar, founder, eBay
                            </div>
                        </div>
                    </Col>

                </Row >
                {naslov0}
                <Row className='w-100'>
                    <Col xs={{ span: 10, offset: 1 }} ><hr className="mb-5 background mx-auto" style={{ backgroundColor: 'black' }} /></Col>
                </Row>

                <Row className="pb-5 px-0 mx-0 mb-sm-5 px-md-5 mx-sm-5 mx-md-5 mx-auto justify-content-center">
                    {items.map((item, i) => {
                        return (
                            item === null ? '' : item.date === '' ?
                                <Col key={i} xs={12} sm={12} md={3} className="celaVest text-justify px-auto mx-5 mx-sm-5 mx-md-4 mx-lg-2 mx-xl-4 px-xl-1 px-lg-1">
                                    <Row className="datumVest"></Row>
                                    <Row><span className="PrviLink pt-3" ></span></Row>
                                    <Row><span className="velikiFont py-3">{item.description}</span>
                                    </Row>
                                </Col>
                                :
                                <Col key={i} xs={12} sm={12} md={3} className="celaVest text-justify px-auto mx-5 mx-sm-5 mx-md-4 mx-lg-2 mx-xl-4 px-xl-2 px-lg-1">
                                    <Row> <span className="datumVest mx-lg-3 mx-xl-0">{item.date}</span></Row>
                                    <Row><a className="PrviLink pt-3 mx-lg-3 mx-xl-0" href={item.link} rel="noopener noreferrer" target="_blank">{item.title}</a></Row>
                                    <Row><span className="ostaliLinkovi py-3 mx-lg-3 mx-xl-0">{item.description}... <a className="PrviLink" href={item.link} rel="noopener noreferrer" target="_blank">{this.props.lang === "en" ? "more..." : "više..."}</a></span>
                                    </Row>
                                </Col>);
                    }
                    )}

                    {/* <Col xl={{ span: 2, offset: 2 }} md={{ span: 3, offset: 1 }}>
                        In terms of entrepreneurship and innovation, Airbnb is an absolutely brilliant company. Based on the simple idea of helping people rent out their homes to tourists, the company’s yearly re...  više...
                    </Col>
                    <Col xl={{ span: 2, offset: 2 }} md={{ span: 3, offset: 1 }}>
                        International Women's Day (March 8) was 2018's most-talked-about moment on Facebook. Research manager Sheida Neman revealed in a blog post that conversation was generated by women and men, a... više...
                    </Col> */}
                </Row>
            </section>
        );
    }
}

export default Home;