import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';
import 'core-js/fn/object/assign';
import 'core-js/fn/promise';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter
} from "react-router-dom";


import './index.css';
import Site from './Site';
import * as serviceWorker from './serviceWorker';

//startsWith polyfill
if (!String.prototype.startsWith) {
    // eslint-disable-next-line no-extend-native
    String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}
//------------------------------

ReactDOM.render(
<BrowserRouter>
    <Site />
</BrowserRouter>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();
