import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from './imgs/dmmca-logo@2x.png';
import logoSVG from './imgs/plus-media-logo-vector.svg';
import './css/Site.css';

class Navigacija extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state={
            drop: false,
            rot: 0
        }
        this.handleDropdownSelect=this.handleDropdownSelect.bind(this);
        this.klikMeni=this.klikMeni.bind(this);
    }
    klikMeni=()=>{
        if(window.screen.width<992){
            document.getElementsByClassName('navbar-toggler')[0].click();
        }          
    };
    handleDropdownSelect(key) {
        this.setState({
            drop: key,
            rot: 0 //this.state.rot === 180 ? 0 : 180
        });
        // if(window.screen.width<768){
        //     document.getElementsByClassName('navbar-toggler')[0].click();
        // }            
    }
    dugmeKlik = (e) => {
        this.setState({ rot: this.state.rot === 180 ? 0 : 180 });
    }
    render() {
        var meni = null;
        var title =null;
        if (this.props.lang === "sr") {
            title = <span>Ko smo &nbsp;&nbsp; <FontAwesomeIcon icon="angle-down" size='1x' transform={{ rotate: this.state.rot }} /></span>;
            meni = <div className="NavbarMeni w-nav m-auto h-100"> <Navbar className='px-0 my-auto' collapseOnSelect expand="lg" >
                <Navbar.Brand><NavLink onClick={(e) => { this.handleDropdownSelect(false); this.klikMeni(); }} to="/"><img alt='Plus Media' className="Logo mt-md-0 ml-3" src={navigator.userAgent.indexOf('Trident') > -1 ? logo : logoSVG} /></NavLink></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto">
                    </Nav>
                    <Nav className="mt-0 py-0 my-2 mr-5">
                        <NavDropdown onClick={(e) => this.dugmeKlik(e)} className={this.state.drop ? 'Navlink aktivMeni px-2 py-0 my-0 mx-1' : 'Navlink px-2 py-0 my-0 mx-1'} title={title}>
                            <NavDropdown.Item as="div">
                                <NavLink className="dropdown-item Navlink px-2 py-2 my-0" onClick={(e) => { this.handleDropdownSelect(true); this.klikMeni(); }} activeClassName="aktivPodMeni px-2 py-2 my-0" to="/o-nama">O nama</NavLink>
                            </NavDropdown.Item>
                            <NavDropdown.Item as="div">
                                <NavLink className="dropdown-item Navlink px-2 py-2 my-0" onClick={(e) => { this.handleDropdownSelect(true); this.klikMeni(); }} activeClassName="aktivPodMeni px-2 py-2 my-0" to="/reg_mreza">Regionalna mreža</NavLink>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Item className="Navlink mt-md-2 mx-1">
                            <NavLink className="Navlink my-2 my-md-0 py-2 px-2 mx-0" activeClassName="aktivMeni my-2 my-md-0 py-2 px-2 mx-0" onClick={(e) => {this.handleDropdownSelect(false);this.klikMeni();}} to="/usluge">Čime se bavimo</NavLink>
                        </Nav.Item>
                        <Nav.Item className="Navlink mt-2 ml-0">
                            <NavLink className="Navlink my-2 my-md-0 py-2 px-2 ml-0" activeClassName="aktivMeni my-2 my-md-0 py-2 px-2 ml-0" onClick={(e) => {this.handleDropdownSelect(false);this.klikMeni();}} to="/kontakt">Kontakt</NavLink>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar></div>;
        }
        else {
            title = <span>Who we are &nbsp;&nbsp; <FontAwesomeIcon icon="angle-down" size='1x' transform={{ rotate: this.state.rot }} /></span>;
            meni = <div className="NavbarMeni w-nav m-auto h-100"> <Navbar className='px-0 my-auto' collapseOnSelect expand="lg">
                <Navbar.Brand><NavLink onClick={(e) => { this.handleDropdownSelect(false); this.klikMeni();}} to="/en"><img alt='Plus Media' className="Logo mt-md-0 ml-3" src={navigator.userAgent.indexOf('Trident') > -1 ? logo : logoSVG} /></NavLink></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto">
                    </Nav>
                    <Nav className="mt-0 py-0 my-2 mr-5">
                        <NavDropdown id='AboutMenu' onClick={(e) => this.dugmeKlik(e)} className={this.state.drop ? 'Navlink aktivMeni px-2 py-0 my-0 mx-1' : 'Navlink px-2 py-0 my-0 mx-1'} title={title}>
                            <NavDropdown.Item as="div">
                                <NavLink className="dropdown-item Navlink px-2 py-2 my-0" activeClassName="aktivPodMeni px-2 py-2 my-0" onClick={(e) => {this.handleDropdownSelect(true);this.klikMeni();}} to="/en/our-story">Our story</NavLink>
                            </NavDropdown.Item>
                            <NavDropdown.Item as="div">
                                <NavLink className="dropdown-item Navlink px-2 py-2 my-0" activeClassName="aktivPodMeni px-2 py-2 my-0" onClick={(e) => {this.handleDropdownSelect(true);this.klikMeni();}} to="/en/regional-network">Regional network</NavLink>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Item className="Navlink mt-md-2 mx-1">
                            <NavLink className="Navlink my-2 my-md-0 py-2 px-2 mx-0" activeClassName="aktivMeni my-2 my-md-0 py-2 px-2 mx-0" onClick={(e) => {this.handleDropdownSelect(false);this.klikMeni();}} to="/en/services">What we do</NavLink>
                        </Nav.Item>
                        <Nav.Item className="Navlink mt-2 ml-0">
                            <NavLink className="Navlink my-2 my-md-0 py-2 px-2" activeClassName="aktivMeni my-2 my-md-0 py-2 px-2" onClick={(e) => {this.handleDropdownSelect(false);this.klikMeni();}} to="/en/contact-us">Contact us</NavLink>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar></div>;
        }
        return (
            meni
        );
    }
}

export default Navigacija;