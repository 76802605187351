import React, { Component } from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
//import Strelica from './imgs/usluge/arrow.svg';

class HeadOfArticle extends Component {
    constructor(props) {
        super(props);
        this.state = { rot: 0, transform: 'rotate(0)', boja: '#6F6E73', d:'M1 1l10.5 10L22 1' };
        this.props = props;
    }
    dugmeKlik = (e) => {
        this.setState({
            transform: this.state.rot === 180 ? 'rotate(0)' : 'rotate(180)',
            rot: this.state.rot === 180 ? 0 : 180,
            boja: this.state.rot === 180 ? '#6F6E73' : '#26ade1',
            d: this.state.rot === 180 ? 'M1 1l10.5 10L22 1' : 'M22 12L11.5 2 1 12'
         });
    }
    render() {
        this.Strelica = <svg xmlns="http://www.w3.org/2000/svg" width="23" height="13" viewBox="0 0 23 13">
            <path fill="none" fillRule="nonzero" stroke={this.state.boja} strokeLinecap="round" strokeWidth="2" d={this.state.d} />
        </svg>;
        return (
            <Row onClick={(e) => this.dugmeKlik(e)} className={this.state.rot === 180 ? "podnaslovAktiv ml-0" : "podnaslovUsluge ml-0"}>
                <Col xs={9} md={8} lg={9}>{this.props.tekst}
                </Col>
                <Col xs={{ span: 1, offset: 1 }} md={2} lg={{ span: 1, offset: 1 }} >
                    {this.Strelica}
                    {/* <img alt='arrow' src={Strelica} /> */}
                    {/* <FontAwesomeIcon icon="angle-down" size='2x' transform={{ rotate: this.state.rot }} /> */}
                </Col>
            </Row>
        )
    }
}

export default HeadOfArticle;