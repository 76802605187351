import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Collapsible from 'react-collapsible';


import './css/Site.css';
import HeadOfArticle from './HeadOfArticle';

class Artikl extends Component {
    constructor(props) {
        super(props);
        this.state = { lang: props.lang };
        this.props = props;
    }

    render() {

        return (
            
            <article className={this.props.pozicija === '1' ? "col-12 py-3 bg-transparent": "col-12 py-3 border-top border-kolor bg-transparent"} >

                <Collapsible accordionPosition={this.props.pozicija} classParentString="Collapsible podnaslov mb-0 pb-0" className="podnaslov mb-0 pb-0" trigger={<HeadOfArticle tekst={this.props.naslov} />} >
                    <Row className='mx-0 mt-3'>
                        <Col xs={{ span: 11 }}>
                            {/* <h3 className="podnaslov mb-0 pb-0">Communication Planning</h3>                               */}
                            {/* <hr className="hr-serv" /> */}
                        </Col>
                        {/* <Col xs={2}></Col> */}
                    </Row>
                    <Row className="bg-white">
                        {/* <Col xs={2}>
                            <img className="mt-2" alt={this.props.naslov} src={this.props.slika} />
                        </Col> */}
                        <Col xs={{ span: 12, offset: 0 }} className="ml-0">
                            <p className="mx-3 ostaliLinkovi">
                                {this.props.tekst}
                            </p>
                        </Col>
                    </Row>
                </Collapsible>

            </article>
        );
    }
}

export default Artikl;