import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import bek_slika from '../imgs/backs/KontaktBack.jpg';
//import Mapa from '../imgs/mapa.jpg';
import NovaMapa from '../imgs/Plus-Media-nova-mapa.png';
import '../css/Site.css';

class Kontakt extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }
    render() {
        var tekst = <Col xs={{ span: 10, offset: 2 }} sm={{ span: 8, offset: 4 }} md={{ span: 4, offset: 1 }} xl={{ span: 4, offset: 2 }} className="mt-0 pt-3 pl-5">
            <article>
                <p className="naslovKontakt p-0 m-0">PLUS MEDIA SRBIJA</p>
                <p className="p-0 m-0">Bulevar Zorana Đinđića 71/9/III</p>
                <p className="p-0 m-0">11070 Novi Beograd</p>
                <p className="p-0 m-0">Tel. <a href='tel:+381114024350'>(+381 11) 402 43 50</a></p>
                <p className="pt-0 mt-0">E-mail: <a href='mailto:office@plusmedia.rs'>office@plusmedia.rs</a></p>
            </article>
            <article>
                <p className="naslovKontakt pb-0 mb-0">PLUS MEDIA D.O.O. BIH</p>
                <p className="p-0 m-0">Jovana Dučića 74A</p>
                <p className="p-0 m-0">78000 Banja Luka</p>
                <p className="p-0 m-0">Tel. <a href='tel:+38751345630'>(+387 51) 345 630</a></p>
                <p className="pt-0 mt-0">E-mail: <a href='mailto:office@plusmedia-rs.com'>office@plusmedia-rs.com</a></p>
            </article>
            <article>
                <p className="naslovKontakt pb-0 mb-0">PLUS MEDIA D.O.O. CRNA GORA</p>
                <p className="p-0 m-0">Cetinjski put UP1PPL5</p>
                <p className="p-0 m-0">81120 Podgorica</p>
                <p className="p-0 m-0">Tel. <a href='tel:+38220206960'>(+382 20) 206 960</a></p>
                <p className="pt-0 mt-0">E-mail: <a href='mailto:office@plusmedia.me'>office@plusmedia.me</a></p>
            </article>
            <article>
                <p className="naslovKontakt pb-0 mb-0">PLUS MEDIA DOOEL SKOPJE</p>
                <p className="p-0 m-0">Aminta 3, 6/1</p>
                <p className="p-0 m-0">1000 Skopje Macedonia</p>
                <p className="p-0 m-0">Tel. <a href='tel:+38923221454'>(+389 2) 322 14 54</a></p>
                <p className="pt-0 mt-0">E-mail: <a href='mailto:office@plusmedia.mk'>office@plusmedia.mk</a></p>
            </article>
        </Col>;
        if (this.props.lang === "en") {
            tekst = <Col xs={{ span: 10, offset: 2 }} sm={{ span: 8, offset: 4 }} md={{ span: 4, offset: 1 }} xl={{ span: 4, offset: 2}} className="mt-0 pt-3 pl-5">
                <article>
                    <p className="naslovKontakt p-0 m-0">PLUS MEDIA SRBIJA</p>
                    <p className="p-0 m-0">Bulevar Zorana Đinđića 71/9/III</p>
                    <p className="p-0 m-0">11070 Novi Beograd</p>
                    <p className="p-0 m-0">Tel. <a href='tel:+381114024350'>(+381 11) 402 43 50</a></p>
                    <p className="pt-0 mt-0">E-mail: <a href='mailto:office@plusmedia.rs'>office@plusmedia.rs</a></p>
                </article>
                <article>
                    <p className="naslovKontakt pb-0 mb-0">PLUS MEDIA D.O.O. BIH</p>
                    <p className="p-0 m-0">Jovana Dučića 74A</p>
                    <p className="p-0 m-0">78000 Banja Luka</p>
                    <p className="p-0 m-0">Tel. <a href='tel:+38751345630'>(+387 51) 345 630</a></p>
                    <p className="pt-0 mt-0">E-mail: <a href='mailto:office@plusmedia-rs.com'>office@plusmedia-rs.com</a></p>
                </article>
                <article>
                    <p className="naslovKontakt pb-0 mb-0">PLUS MEDIA D.O.O. CRNA GORA</p>
                    <p className="p-0 m-0">Cetinjski put UP1PPL5</p>
                    <p className="p-0 m-0">81120 Podgorica</p>
                    <p className="p-0 m-0">Tel. <a href='tel:+38220206960'>(+382 20) 206 960</a></p>
                    <p className="pt-0 mt-0">E-mail: <a href='mailto:office@plusmedia.me'>office@plusmedia.me</a></p>
                </article>
                <article>
                    <p className="naslovKontakt pb-0 mb-0">PLUS MEDIA DOOEL SKOPJE</p>
                    <p className="p-0 m-0">Aminta 3, 6/1</p>
                    <p className="p-0 m-0">1000 Skopje Macedonia</p>
                    <p className="p-0 m-0">Tel. <a href='tel:+38923221454'>(+389 2) 322 14 54</a></p>
                    <p className="pt-0 mt-0">E-mail: <a href='mailto:office@plusmedia.mk'>office@plusmedia.mk</a></p>
                </article>
            </Col>;
        }
        return (
            <Row className="sekcija w-100 mx-0">
                <Row className='w-100 mx-0'>
                    <Col className="p-0" xs={12}>
                        <img alt={this.props.lang === "en" ? "Contact" : "Kontakt"} src={bek_slika} className="w-100" />
                    </Col>
                </Row >
                <Row className="ispisOnama w-100">
                    <Col xs={{ span: 10, offset: 2 }} md={{ span: 12, offset: 0 }} className="naslov text-left  text-md-center pt-5">{this.props.lang === "en" ? "Contact" : "Kontakt"}</Col>
                    <Col xs={{ span: 10, offset: 1 }} ><hr className="mb-5 background mx-auto" style={{ backgroundColor: 'black' }} /></Col>
                    <Row className="w-100">
                        <Col xs={12} md={{ span: 6, offset: 0 }} className="ml-0 my-3 px-4">
                            <img className="offset-1 offset-md-4 mapa" alt='mapa' src={NovaMapa} width='90%' />
                        </Col>
                        {tekst}
                    </Row>
                </Row>
            </Row>
        );
    }
}

export default Kontakt;