import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
//import { NavLink } from "react-router-dom";

import './css/Site.css';
// import RevizijaI from "./docs/revizija-fin-izvestaj.pdf";
// import RevizijaII from "./docs/revizija-kons-finans-izvestaj.pdf";

class Podnozje extends Component {
    constructor(props){
        super(props);
        this.props=props;
    }
    render() {
        var futer = null;
        if (this.props.lang === "sr") {
            futer = <footer className="podno px-0 mx-0 pt-3 justify-content-center">
                <Row className="pod_vrh pb-3 pb-md-0">
                    <Col className="mb-3 mx-md-auto" md={{ span: 3, offset: 0 }} xl={4} >
                        <Row>
                            {/* <NavLink className="PrviLink mx-auto mx-md-0" to="/">Početna</NavLink> */}
                        </Row>
                    </Col>
                    {/* <Col className="mb-3" md={{ span: 0, offset: 0 }}>
                        <Row>
                            <NavLink className="PrviLink mx-auto mx-md-0" to="/o-nama">Ko smo</NavLink>
                        </Row>
                        <Row>
                            <NavLink className="ostaliLinkovi mx-auto mx-md-0" to="/o-nama">O nama</NavLink>
                        </Row>
                        <Row>
                            <NavLink className="ostaliLinkovi mx-auto mx-md-0" to="/reg_mreza">Regionalna mreža</NavLink>
                        </Row>
                        <Row>
                        <NavLink className="ostaliLinkovi" to="/o-nama">Mindshare worldwide</NavLink>
                    </Row>
                    </Col> */}
                    <Col className="mb-3 mr-md-5 justify-content-end" md={{ span: 2, offset: 0 }}>
                        <Row>
                            <p className="col-12 PrviLink mx-auto px-0 mx-md-0 my-1 fontDole">Adresa</p>
                        </Row>
                        <Row>
                            <p className="col-12 ostaliLinkovi mx-auto px-0 mx-md-0 my-0 fontDole">Bulevar Zorana Đinđića 71/9/III</p>
                            <p className="col-12 ostaliLinkovi mx-auto px-0 mx-md-0 my-0 fontDole">11070 Novi Beograd</p>
                            <p className="col-12 ostaliLinkovi mx-auto px-0 mx-md-0 my-0 fontDole">Srbija</p>
                        </Row>
                    </Col>
                    <Col className="mb-3 justify-content-start" md={{ span: 2, offset: 0 }}>
                        <Row>
                            <p className="col-12 PrviLink mx-auto px-0 mx-md-0 my-1 fontDole">Kontakt</p>
                        </Row>
                        <Row>
                            <p className="col-12 ostaliLinkovi mx-auto px-0 mx-md-0 my-0 fontDole">+381 11 402 43 50</p>
                            <p className="col-12 ostaliLinkovi mx-auto px-0 mx-md-0 my-0 fontDole">office@plusmedia.rs</p>
                        </Row>
                    </Col>
                    <Col className="mb-3 mx-md-auto" md={{ span: 3, offset: 0 }}>
                        {/* <Row>
                            <span className="PrviLink mx-auto mx-md-0">Mišljenje revizora</span>
                        </Row>
                        <Row>
                            <a target="_blank" rel="noopener noreferrer" href={RevizijaI} className="ostaliLinkovi mx-auto mx-md-0">Mišljenje revizora I</a>
                        </Row>
                        <Row>
                            <a target="_blank" rel="noopener noreferrer" href={RevizijaII} className="ostaliLinkovi mx-auto mx-md-0">Mišljenje revizora II</a>
                        </Row> */}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-center pod_dno justify-content-center" >
                        <span className="fontFooter align-top text-center">© Plus Media  Developed by Huge Media</span>
                    </Col>
                </Row>
            </footer>;
        }
        else {
            futer = <footer className="podno px-0 mx-0 pt-3">
                <Row className="pod_vrh pb-3 pb-md-0 px-0 mx-0">
                    <Col className="mb-3 mx-md-auto" md={{ span: 3, offset: 0 }} xl={4}>
                        <Row>
                            {/* <NavLink className="PrviLink mx-auto mx-md-0" to="/en">Home</NavLink> */}
                        </Row>
                    </Col>
                    {/* <Col className="mb-3" md={{ span: 0, offset: 0 }}>
                        <Row>
                            <NavLink className="PrviLink mx-auto mx-md-0" to="/en/our-story">Who we are</NavLink>
                        </Row>
                        <Row>
                            <NavLink className="ostaliLinkovi mx-auto mx-md-0" to="/en/our-story">Our story</NavLink>
                        </Row>
                        <Row>
                            <NavLink className="ostaliLinkovi mx-auto mx-md-0" to="/en/regional-network">Regional network</NavLink>
                        </Row>
                        <Row>
                        <NavLink className="ostaliLinkovi" to="/o-nama">Mindshare worldwide</NavLink>
                    </Row>
                    </Col> */}
                    <Col className="mb-3" md={{ span: 2, offset: 0 }}>
                        <Row>
                            <p className="col-12 PrviLink mx-auto px-0 mx-md-0 my-1 fontDole">Address</p>
                        </Row>
                        <Row>
                            <p className="col-12 ostaliLinkovi mx-auto px-0 mx-md-0 my-0 fontDole">Bulevar Zorana Đinđića 71/9/III</p>
                            <p className="col-12 ostaliLinkovi mx-auto px-0 mx-md-0 my-0 fontDole">11070 Novi Beograd</p>
                            <p className="col-12 ostaliLinkovi mx-auto px-0 mx-md-0 my-0 fontDole">Serbia</p>
                        </Row>
                    </Col>
                    <Col className="mb-3" md={{ span: 2, offset: 0 }}>
                        <Row>
                            <p className="col-12 PrviLink mx-auto px-0 mx-md-0 my-1 fontDole">Contact</p>
                        </Row>
                        <Row>
                            <p className="col-12 ostaliLinkovi mx-auto px-0 mx-md-0 my-0 fontDole">+381 11 402 43 50</p>
                            <p className="col-12 ostaliLinkovi mx-auto px-0 mx-md-0 my-0 fontDole">office@plusmedia.rs</p>
                        </Row>
                    </Col>
                    <Col className="mb-3 mx-md-auto" md={{ span: 2, offset: 0 }}>
                        {/* <Row className="PrviLink">Mišljenje revizora</Row>
                        <Row>
                            <a target="_blank" rel="noopener noreferrer" href={RevizijaI} className="ostaliLinkovi">Mišljenje revizora I</a>
                        </Row>
                        <Row>
                            <a target="_blank" rel="noopener noreferrer" href={RevizijaII} className="ostaliLinkovi">Mišljenje revizora II</a>
                        </Row> */}
                    </Col>
                </Row>
                <Row className='w-100 mx-auto'>
                    <Col xs={12} className="text-center pod_dno mx-auto justify-content-center" >
                        <span className="fontFooter align-top">© Plus Media  Developed by Huge Media</span>
                    </Col>
                </Row>
            </footer>;
        }
        return (
            futer
        );
    }
}

export default Podnozje;