import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import bek_slika from '../imgs/backs/OnamBack.jpg';
import '../css/Site.css';

class Onama extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }
    render() {
        return (
            <Row className="sekcija w-100 ml-3">
                <Row className='w-100 mx-0 px-0'>
                    <Col className="p-0" xs={12}>
                        <img alt={this.props.lang === "en" ? "Our story" : "O nama"} src={bek_slika} className="w-100" />
                    </Col>
                </Row >
                {this.props.lang === "en" ?
                    <Row className="ispisOnama center col-12">
                        <Col xs={{ span: 10, offset: 2 }} md={{ span: 12, offset: 0 }} className="naslov text-left text-md-center pt-5">Our story</Col>
                        <Col xs={{ span: 10, offset: 1 }} ><hr className="mb-5 background mx-auto" style={{ backgroundColor: 'black' }} /></Col> 
                        <Row className='col-12 px-0 px-sm-5'>
                            <Col xs={12} md={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
                                <Col xs={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} className="mx-3 p-xxs-3 mx-lg-0 my-0 px-5 px-md-3">
                                    <h3 className="podnaslov">Plus Media</h3>
                                    <p className="ostaliLinkovi">Is an independent agency that specializes in creating your communication strategy and overall media presence.</p>
                                    <p className="ostaliLinkovi">We are a agency, but we are experienced professionals, with just the right blend of energy and know-how necessary to offer fast and all-encompassing solutions with guaranteed results to our clients.</p>
                                    <p className="ostaliLinkovi">Our references and experience include all types of media: television, print, out-of-home, radio and digital.</p>

                                    <p className="ostaliLinkovi">Our approach to work is based on our vast experience in analysis, planning and implementation of advertising messages for a wide and versatile portfolio of clients and brands.</p>
                                </Col>
                            </Col>
                            <Col xs={12} md={{ span: 5, offset: 0 }} lg={{ span: 4, offset: 0 }}>
                                <Col xs={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} className="mx-3 p-xxs-3 px-5 mx-lg-0 my-0 px-md-3 my-5 my-md-0">
                                    <h3 className="podnaslov">The Plus Media Way</h3>
                                    <p className="ostaliLinkovi">We believe that detailed knowledge of the market and possibilities offered by every type of media, as well as the utmost care dedicated to the unique traits of each client, form the basis of our ability to properly respond to your every request.</p>
                                    <p className="ostaliLinkovi">This is why we have established our agency as a “one stop shop” solution for developing and deploying a complete communication strategy for our clients. Based on detailed research and expert analysis of the competition and target groups, we create a media strategy optimized according to your needs. We offer media buying for all types of media on the market, with competitive prices.</p>
                                    <p className="ostaliLinkovi">In short, PLUS MEDIA is an all-encompassing solution for all your communication needs.</p>
                                    <p className="podnaslov">As your agency, we guarantee total dedication towards building a partnership based on mutual confidence, to our mutual satisfaction.</p>
                                    <p className="podnaslov">Welcome to Plus Media.</p>
                                </Col>
                            </Col>
                        </Row>
                    </Row>
                    :
                    <Row className="ispisOnama center col-12">
                        <Col xs={{ span: 10, offset: 2 }} md={{ span: 12, offset: 0 }} className="naslov text-left text-md-center pl-0 pt-5">O nama</Col>
                        <Col xs={{ span: 10, offset: 1 }} ><hr className="mb-5 background mx-auto" style={{ backgroundColor: 'black' }} /></Col> 
                        <Row className='col-12 px-0 px-sm-5'>
                            <Col md={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
                                <Col xs={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} className="mx-3 p-xxs-3 mx-lg-0 my-0 px-5 px-md-3">
                                    <h3 className="podnaslov">Plus Media</h3>
                                    <p className="ostaliLinkovi">Je nezavisna agencija specijalizovana za kreiranje vaše komunikacione strategije i kompletnog medijskog prisustva.</p>
                                    <p className="ostaliLinkovi">Kao agencija u kojoj su okupljeni iskusni profesionalci, posedujemo pravu kombinaciju energije i znanja neophodnih da našim klijentima pružimo brzu i sveobuhvatnu uslugu sa garantovanim rezultatima.</p>
                                    <p className="ostaliLinkovi">Naše reference i iskustvo obuhvataju sve vrste medija: televiziju, štampu, out-of-home, radio i digitalne medije.</p>
                                    <p className="ostaliLinkovi">Naš pristup poslu je izgrađen na višegodišnjem iskustvu u istraživanju, analizi, planiranju i implementaciji oglašavanja za širok i raznovrstan portfolio klijenata i njihovih brendova.</p>
                                </Col>
                            </Col>
                            <Col md={{ span: 5, offset: 0 }} lg={{ span: 4, offset: 0 }}>
                                <Col xs={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} className="mx-3 p-xxs-3 px-5 mx-lg-0 my-0 px-md-3 my-5 my-md-0">                             
                                    <h3 className="podnaslov">Plus Media princip</h3>
                                    <p className="ostaliLinkovi">Verujemo da zahvaljujući našem detaljnom poznavanju tržišta i mogućnosti koje svaki medij pruža, kao i maksimalnoj pažnji koju posvećujemo specifičnostima svakog našeg klijenta, možemo da odgovorimo na sve vaše zahteve u oblasti medijskog nastupa.</p>
                                    <p className="ostaliLinkovi">Zato smo našu ponudu organizovali kao "one stop shop" rešenje za razvoj i primenu  celokupne komunikacione strategije klijenata. Na osnovu detaljnih istraživanja i stručnih analiza konkurencije, ciljnih grupa, navika i trendova, stvaramo medijsku strategiju optimizovanu prema vašim potrebama. Nudimo zakup svih vrsta medija dostupnih na tržištu po konkurentnim cenama. Pored toga, na raspolaganje vam stavljamo i prateće usluge kao što su izrada ili adaptacija kreativnih rešenja, BTL aktivnosti i PR, koje putem naših partnerskih agencija obezbeđujemo kao podršku vašoj komunikaciji.</p>
                                    <p className="ostaliLinkovi">Ukratko, PLUS MEDIA je sveobuhvatno rešenje za sve vaše komunikacijske potrebe.</p>
                                    <p className="podnaslov">Kao vaša agencija, posvećeni smo izgradnji odnosa uzajamnog poverenja i partnerstva, na obostrano zadovoljstvo.</p>
                                    <p className="podnaslov">Dobrodošli u Plus Media.</p>
                                </Col>
                            </Col>
                        </Row>
                    </Row>
                }
            </Row>
        );
    }
}

export default Onama;