import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
// import { Button } from 'react-bootstrap';
import './css/Site.css';
import Navigacija from './Navigacija';
//import bek_slika from './imgs/plus-media-site-pozadinska-slika-1920px.png';

class Zaglavlje extends Component {
    constructor(props) {
        super(props);
        this.state = { lang: this.props.lang };
        this.props = props;
    }

    render() {
        
        return (
            <header>
                <Row className="zaglav w-100 px-0 mx-0">
                    <Col className="p-0" xs={12}>
                        <Navigacija lang={this.props.lang} />
                    </Col>
                </Row>
                {/* <Row>
                    <Col className="p-0" xs={12}>
                        <img alt='' src={bek_slika} className="w-100" />
                    </Col>
                </Row > */}
            </header>
        );
    }
}

export default Zaglavlje;