import React, { Component } from 'react';
// import Row from 'react-bootstrap/lib/Row';
// import Col from 'react-bootstrap/lib/Col';
import { Route, Switch } from "react-router-dom";
import './css/Site.css';
import Home from "./sr/Home";
import NoMatch from "./404";
import Onama from "./sr/Onama";
import RegMreza from "./sr/RegMreza";
import Usluge from "./sr/Usluge";
import Kontakt from "./sr/Kontakt";

class Sadrzaj extends Component {
// eslint-disable-next-line no-useless-constructor
constructor(props){
    super(props);
    this.props=props;
}
    render() {
        // if(this.props.lang==="sr"){
        //     window.history.pushState({urlPath:'/'},"",'/');
        // }
        // else{
        //     window.history.pushState({urlPath:'/en'},"",'/en');
        // }
        return(
            <div className='mb-5'>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/o-nama" component={Onama} />
                <Route path="/reg_mreza" component={RegMreza} />
                <Route path="/usluge" component={Usluge} />
                <Route path="/kontakt" component={Kontakt} />
                <Route exact path="/en" render={({props}) => <Home lang="en" {...{props}} />} />
                <Route path="/en/our-story" render={({props}) => <Onama lang="en" {...{props}} />} />
                <Route path="/en/regional-network" render={({props}) => <RegMreza lang="en" {...{props}} />} />
                <Route path="/en/services" render={({props}) => <Usluge lang="en" {...{props}} />} />
                
                <Route path="/en/contact-us" render={({props}) => <Kontakt lang="en" {...{props}}/>} />
               
                <Route component={NoMatch} />
               
            </Switch>
            </div>
        );
    }
}

export default Sadrzaj;