import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import '../css/Site.css';
import bek_slika from '../imgs/backs/UslugeBack.jpg';
import Artikl from '../ServiceArtikl';
// import jpgKonsalt from '../imgs/usluge/konsalting.jpg';
// import jpgKreativa from '../imgs/usluge/izrada-kreativnih-resenja.jpg';
// import jpgZakup from '../imgs/usluge/zakup medija.jpg';
// import jpgNoviMediji from '../imgs/usluge/novi-mediji.jpg';
// import jpgMediaPlan from '../imgs/usluge/medijsko-planiranje.jpg';
// import jpgIstrazivanje from '../imgs/usluge/istrazivanje-i-analiza.jpg';
// import jpgKomPlan from '../imgs/usluge/komunikacijsko-planiranje.jpg';
import DataEN from '../data/usluge_en.json';
import DataRS from '../data/usluge_rs.json';

class Usluge extends Component {
    constructor(props){
        super(props);
        this.props=props;
    }
    // kliknuto(e) {
    //     console.log(e);
    // }
    DataLang = null;
    uzmiArtikal = (item, index) => {
        return (<Artikl key={index} pozicija={item.pozicija} naslov={item.naslov}
            tekst={item.tekst}
            slika={item.slika}>
        </Artikl>);
    }

    render() {
        this.DataLang = this.props.lang === "en" ? DataEN : DataRS;
        //this.DataLang.usluge.forEach(this.uzmiArtikal);
        return (
            <Row className="sekcija w-100 mx-0">
                <Row className='w-100 mx-0'>
                    <Col className="p-0" xs={12}>
                        <img alt={this.props.lang === "en" ? "Services" : "Usluge"} src={bek_slika} className="w-100" />
                    </Col>
                </Row >
                <Row className="ispisOnama center col-12 mx-auto px-auto pl-md-0 ml-xl-5">
                    <Col xs={{ span: 10, offset: 2 }} md={{ span: 12, offset: 0 }} className="naslov text-left text-md-center pl-0 pt-5">{this.DataLang.naslov}</Col>
                    <Col xs={{ span: 10, offset: 1 }} ><hr className="mb-5 background mx-auto" style={{ backgroundColor: 'black' }} /></Col> 
                    <Row className='col-12 justify-content-center'>
                        <Col xs={{ span: 11, offset: 1 }} md={{ span: 4, offset: 1 }} xl={{ span: 4, offset: 0 }} className='mr-xl-5'>
                            <Col xs={{ span: 10, offset: 0 }} sm={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 0 }} className="mx-auto px-0 w-100 leviTekst">
                                {/* <h2 className="naslov text-center pt-5">{this.DataLang.naslov}</h2> */}
                                <h3 className="podnaslov">{this.DataLang.podnaslov}</h3>
                                <p>{this.DataLang.tekst}</p>
                            </Col>
                        </Col>
                        <Col xs={{ span: 12, offset: 0 }} sm={{ span: 10, offset: 2 }} md={{ span: 4, offset: 0 }} xl={{ span: 5, offset: 0 }} className='leviTekst border border-usluge px-0 mb-3 mx-3 ml-5  mx-md-5 mx-xl-0' >
                          
                            {this.DataLang.usluge.map(this.uzmiArtikal)}
                         
                        </Col>
                    </Row>
                    {/* <Artikl pozicija='1' naslov='Communication Planning'
                        tekst='Our most important task is to gain a better understanding of your goals. Only then can we efficiently utilize our resources and expertise to create a communication strategy, one that will include all the relevant channels and which will be certain to find its way to the consumer. We are not afraid to be creative in our implementation of all types of media and we do not back away from innovative solutions. But we always keep in mind one ultimate goal: a maximum return of investments for our'
                        slika={jpgKomPlan}>
                    </Artikl>
                    <Artikl pozicija='2' naslov='Research and Analysis'
                        tekst='Knowing who it is you are speaking to is as important as mastering what it is you wish to say. This is why we always have the most up-to-date information from local and regional markets at our disposal. We gather data from all available and relevant databases. We use tried and tested methods of analysis in order to promptly discern existing trends and predict future ones. This is accompanied by regular overviews of current habits and needs of consumers. On top of this, the data we receive through the Mindshare network gives us a competitive advantage in relation to local agencies. Because of this, we have a unique capability to compare local data with worldwide and regional trends, so that we can create the best possible media strategy for your brand.'
                        slika={jpgIstrazivanje}>
                    </Artikl>
                    <Artikl pozicija='3' naslov='Media Planning'
                        tekst='Our service includes all types of media. We can guarantee a successful and effective media presence because we base every campaign on detailed data and situational analysis. Utilizing this knowledge, we set campaign goals and communication strategy and create the media mix.'
                        slika={jpgMediaPlan}>
                    </Artikl>
                    <Artikl pozicija='4' naslov='Digital Media'
                        tekst='The growing influence of digital media and advertising cannot be overstated. This is why, when creating your media strategy, we pay special attention to digital communication channels such as display advertising, search engine marketing, social networks or mobile. Some services that we offer include: data gathering and analysis of the market and its trends; landing page creation consulting; making the creative for digital campaigns; creating a unique and detailed media plan for your digital advertising; as well as social network community management.'
                        slika={jpgNoviMediji}>
                    </Artikl>
                    <Artikl pozicija='5' naslov='Media Buying'
                        tekst='Effective media presence means finding the easiest route through the jungle of media space, directly to the hearts and minds of consumers. As an independent agency that fosters its connections with all the relevant ad space providers on the market, Plus Media can guarantee you an optimal relation between resources invested and media effect achieved.'
                        slika={jpgZakup}>
                    </Artikl>
                    <Artikl pozicija='6' naslov='Making or Adapting the Ad Creative'
                        tekst='The key to every message is its content. In order to make your message memorable and relevant, we offer our professional help in creating or adapting your communication. We do copy and design, we build brands and create the public perception of your brand – through all classic advertising and PR channels, ranging from Above the Line and Below the Line, over Business to Business, all the way to proactive and crisis-related PR support.'
                        slika={jpgKreativa}>
                    </Artikl>
                    <Artikl pozicija='7' naslov='Consulting'
                        tekst='Plus Media is an agency with a fresh and unique vision of communication and media, most of all thanks to the diversity and breadth of our employees in all the areas relevant for successful communication. Working with Plus Media gives you access to the huge Mindshare base of knowledge and experiences, in order to create the best possible answer to your communication needs.'
                        slika={jpgKonsalt}>
                    </Artikl> */}
                </Row>
            </Row>
        );
    }
}

export default Usluge;